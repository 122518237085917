
@font-face {
    font-family: 'Gotham-Bold';
    src: url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Gotham-Bold.woff') format('woff'), url('../fonts/Gotham-Bold.ttf')  format('truetype'), url('../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Book';
    src: url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),  url('../fonts/Gotham-Book.woff') format('woff'), url('../fonts/Gotham-Book.ttf')  format('truetype'), url('../fonts/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'subwayregular';
    src: url('../fonts/subway-black-webfont.woff2') format('woff2'),
         url('../fonts/subway-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'subway_shadowregular';
    src: url('../fonts/subway-shadow-webfont.woff2') format('woff2'),
         url('../fonts/subway-shadow-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}