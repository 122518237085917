@import "../../bower/normalize-scss/_normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import './fonts.scss';
@import './variable.scss';
.main-slider {
	// background-color: #f5f5f5;
	position: relative;
	width: 100%;
	// height: 34vw;//38vw;
	// min-height: 8vw;
	height: 100%;
	// margin-bottom: 50px;
	opacity: 0;
	visibility: hidden;
	transition:all 1.2s ease;
	&.slick-initialized {
	  opacity: 1;
	  visibility: visible;
	}
	@media (max-width: 980px){
		top: -7px;
	}
  }
  .slick-slide {
	position: relative;
	height: 38vw;
	&::before {
	  background-color: #000;
	  opacity: .3;
	  z-index: 1;
	}
	video {
	  display: block;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  min-width: 100%;
	  min-height: 100%;
	  width: auto;
	  height: auto;
	  transform:translate(-50%, -50%);
	}
	iframe {
	  position: relative;
	  pointer-events: none;
	}
	figure {
	  position: relative;
	  height: 100%;
	}
	.slide-image {
	  opacity: 0;
	  height: 100%;
	  background-size: cover;
	  background-position: center;
	  transition:all .8s ease;
	  &.show {
		opacity: 1;
	  }
	}
	.image-entity {
	  width: 100%;
	  opacity: 0;
	  visibility: hidden;
	}
	.loading {
	  position: absolute;
	  top: 44%;
	  left: 0;
	  width: 100%;
	}
	.slide-media {
	  animation:slideOut .4s cubic-bezier(0.4, 0.29, 0.01, 1);
	}
	&.slick-active {
	  z-index: 1;
	  .slide-media {
		animation:slideIn 2.4s cubic-bezier(0.4, 0.29, 0.01, 1);
	  }
	  .caption {
		opacity: 1;
		transform:translateY(0);
		transition:all .7s cubic-bezier(0.32, 0.34, 0, 1.62) .6s;
	  }
	}
  }
  
html{
	height: 100%;
	box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
button:focus {outline:0;}
body { 
	margin: 0;
	padding: 0;
	font-family: $book;
	padding-right: 0;
	min-height: 100%;
	position: relative;
	padding-bottom: 100px;
	overflow-x: hidden;
	
}
.see-all{
	border: none;
    background-color: #bf2227;
    color: white;
    padding: 10px 20px;
	text-transform: uppercase;
	margin: 26px 0;
	&:hover{
		opacity: .7;
	}
}
.d-flex{
	display: flex;
	&.justify-content-center{
		justify-content: center;
	}
}
.telephone{
	color: white;
    text-decoration: none;
}
.social-media{
	z-index:2;
	display: flex;
	flex-direction: column;
	right: 0;
	position: fixed;
	padding: 12px;
	line-height: 1;
	top:355px;
	a{
		line-height: 0;
	}
	i{
		position: relative;
		z-index: 1;
		// font-size: 20px;
		margin-top: 10px;
		margin-bottom: 10px;
		// color: white;
		
	}
	i:hover{
		opacity: .5;
	}
	&::before{
		content:' ';
		position: absolute;
		opacity: .7;
		background-color: $primary;
		top: 0;
		left:0;
		bottom:0;
		right: 0;
	}
}
.insta{
	color: white;
	font-size: 20px;
}
.circle{
	background-color: white;
    border-radius: 30px;
    display: inline-block;
    height: 20px;
    line-height: 24px;
    width: 20px;
    font-size: 15px;
	text-align: center;
	&::before{
		opacity: .7;
	}
}
@media (min-width: 970px){
.navbar-nav {
    float: unset;
    margin: unset;
}
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}
ul {
	padding-left: 0;
}
li {
	list-style: none;
}

.contain {
	max-width: 1200px;
	margin: 0 auto;
}

.btn {
	border-radius: 0;
	font-size: 20px;
	&.btn-primary {
		background-color: #bf2227;
		border-color: #bf2227;
		color: white;
		text-transform: uppercase;
		padding: 10px;
    	width: 200px;
	}
	&.btn-primary:hover{
		opacity: .7;
	}
}

.nav-format{
	float: right;
}
.height{
	height: 100px;
}
.navbar-default {
	background-color: $primary;
	border: none;
	border-radius: 0;
	margin-bottom: 0;
	width: 100%;
	.navbar-nav {
		li {
			a {
				color: white;
				text-transform: uppercase;
				font-size: 17px;
				padding-top: 20px;
				padding-bottom: 20px;
			}
			&.active, &:hover {
				background: transparent;
				a {
					background: transparent;
					color: white;
					&:hover {
						background-color: white;
					}
				}
			}
		}
	}
	
	.navbar-brand {
		img {
			max-height: 40px;
		}
	}
}

footer {
	position: absolute;
	background: $primary;
	color: white;
	padding: 50px 0; 
	bottom: 0;
    right: 0;
    left: 0;
	.grid {
		align-items: center;
		grid-template-columns: 1fr 1fr;
		.links {
			justify-self: flex-end;
			@media (max-width:570px) {
				display: flex;
    			flex-flow: column;
			}
			a {
				text-transform: uppercase;
				margin-right: 10px;
				margin-right: 27px;
				&:last-child {
					margin-right: 0;
				}
				&:hover{
					opacity: .5;
				}
			}
		}
	}
}

.grid {
	display: grid;
}
.home-page{
	background-color: #f5f5f5;
	// margin-bottom: 60px;
}
.main-banner{
	// margin-top: 100px;
	position: relative;
	.hero-banners {
		height: 626px;
		.banner {
			width: 100%;
			height: 626px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
	.grid {
		// align-items: center;
		height: 100%;
		background: rgba(0,0,0,0.3);
		position: absolute;
		top: 0;
		width: 100%;
		@media (max-width: 980px){
			top: -7px;
		}
	}
	.text-container {
		font-size: 44px;
		color: white;
		font-family: $bold;
		// text-align: center;
		img {
			max-width: 100%;
		}
	}
}
	.locator {
		padding: 50px 0;
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 1fr 200px;
		align-items: center;
		@media(max-width: 600px) {
			a {
				font-size: 14px
			}
			.grid {
				img {
					max-width: 60px !important;
				}
			}
		}
		.grid {
			align-items: center;
			display: flex;
			
			img {
				max-width: 100px;
				margin-right: 30px;

			}
			.text {
				color: $primary;
				.big-text {
					font-family: $bold;
					text-transform: uppercase;
					font-size: 40px;
				}
				.small-text {
					font-size: 17px;
				}
			}
		}
	}
	.featured-product {
		height: 626px;
		div {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
		}
	}
	.menus {
		@media (max-width:591px) {
			margin-bottom: 36px;
		}
		.grid {
			align-items: center;
			grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
			.menu {
				color: white;
				text-align: center;
				height: 570px;
				@media(max-width: 655px) {
					height: 350px;
				}
				.overlay {
					background: rgba(0,0,0,0.3);
					width: 100%;
					height: 100%;
					display: flex;
					flex-flow: column;
					align-items: center;
					justify-content: center;
				}
				.title {
					font-family: $bold;
					font-size: 40px;
					text-transform: uppercase;
				}
				.description {
					font-size: 17px;
					max-width: 450px;
					margin: 0 auto;
					min-height: 75px;
					margin-bottom: 30px;
				}
			}
		}
	}
	.stores {
		padding: 40px 0;
		.grid {
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
			align-items: center;
			grid-gap: 30px;
			.store {
				justify-self: center;
				text-align: center;
				img{
					height: 135px;
				}
				.text {
					margin-top: 10px;
					text-transform: uppercase; 
					color: $primary;
					font-family: $bold;
					font-size: 24px;
				}
				@media(max-width: 600px) {
					img {
						height: 80px !important;
					}
					.text {
						font-size: 14px !important;
					}
				}
			}
			.store:hover{
				opacity: .5;
			}
		}
	}
// }


//navbar custom  

  
.global-nav {
	position: sticky;
	top: 0;
	z-index: 9999;
	height: 100px;
	width: 100%;
	background: $primary;
	color: #fff;
	line-height: 100px;
	  //-webkit-transition: height .5s, line-height .5s; /* Safari */
	  transition: height .5s, line-height .5s;
	//   .pull-left{
	// 	//   width: 314px;
	//   }
	  .navbar-brand {
		img {
			max-height: 65px;
			transition: .5s;
			&:hover{
				opacity: .5;
			}
		}
		}
		.nav-list {
			list-style: none;
			margin: 0 20px;
			display: flex;
			justify-content: space-around;
			// margin-left: 20px;
			a{
				width: 100%;
    			text-align: center;
			}
			.active{
				background-color: white;
				// padding: 43px 20px;
				color: $primary;
				// transition: .1s;
			}
				cursor: pointer;
				// a{
				// 	padding:20px;
				// }
				a:hover{
					background-color: white;
					// padding: 43px 20px;
					color: $primary;
				}
		  }
  }
  
  .scrolled-nav .navbar-brand img{
	max-height: 35px !important;
  }
  

  .paperclip{
	 	color: $primary;
	  	background-color: transparent;
		cursor: pointer;
	&:hover{
		background-color:$primary;
		color: white;
	}
  }
  .nav-list li {
	list-style: none;
	display: inline-block;
	//padding-left: 20px;
  }
  .scrolled-nav {
	height: 60px !important;
	line-height: 60px !important;
	// .nav-list{
		// .active{
		// 	padding:20px !important;
			
		// }
		// a:hover{
		// 	padding: 20px !important;
		// }
	// }
  }

.sidenav-button{
	cursor: pointer;
	font-size: 30px;
}
// HISTORY
.history {
	// margin-top: 100px;
	.grid-history {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.text {
			margin: 50px 50px;
			font-family: 'Gotham-Book';
			font-weight: lighter;
			color: #353535;
			font-size: 13px;
			line-height: 25px;
			.title{
				font-family: 'Gotham-Book';
				font-size: 30px;
				font-weight: bold;
				margin-bottom: 30px;
				line-height: 34px;
			}
		}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
		}
	}
	>:first-child{
		.text{
			.title{
				&.main{
					text-transform: uppercase;
					color: $primary;
					font-family: 'Gotham-Bold';
					font-size: 30px;
				}
			}
		}
	}
	>:nth-child(even){
		.text{
			order: 2;
		}
	}
}
// HISTORY
//contact

.contact{
	display: grid;
	grid-template-columns: 1fr 1fr;
	.form{
		padding: 50px 100px;
		.title{
			text-transform: uppercase;
			color: $primary;
			font-family: 'Gotham-Bold';
			font-size: 40px;
			padding: 0 0 20px 0;
		}
		input[type='submit']{
			padding: 11px;
			text-transform: uppercase;
			background-color: $secondary;
			border: none;
			color: white;
			width: 100px;
		}
		input[type='submit']:hover{
			opacity: .7;
		}
	}
	.address{
		background-color: $secondary;
		color:white;
		.m-address{
			padding: 50px 100px;	
		}
		h3{
			line-height: 45px;
		}
		
		}
	}
	.parsley-errors-list li {
		font-size: 12px;
		color: #fc4152 !important; }
.parsley-required{
	font-size: 12px;
		color: #fc4152 !important;
}
//careers
.txt-center{
	text-align:center;
}
.t-align{
	text-align: center;
	padding: 30px 0;
}
.main-title{
	text-transform: uppercase;
	color: $primary;
	font-family: 'Gotham-Bold';
	font-size: 40px;
	// text-align: center;
}
.careers{
	.now-hiring{
		padding: 55px 190px;
		.position{
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 0 20px;
			grid-gap: 0 100px;
			padding: 30px 84px;
			color: #687f73;
			li{
				border-bottom: solid 1px;
				padding: 20px;
				font-size: 21px;
				text-align: center;
				a:hover{
					color: $primary;
					font-weight: bold;
				}
			}
			>:last-child{
				border-bottom: none;		
			}
		}
	
	.apply{
		padding: 11px 40px;
		text-transform: uppercase;
		background-color: #005027;
		border: none;
		color: white;
		&:hover{
			opacity: .7;
		}
	}
		
	}
	.application{
		padding: 55px 100px;
		.row{
			input[type='submit']{
				padding: 11px 40px;
				text-transform: uppercase;
				background-color: $secondary;
				border: none;
				color: white;
				&:hover{
					opacity: .7;
			
			}
			}
		}
	}
}
.attach{
	padding: 17px;
	border: none;
	background-color: steelblue;
}
.submit{
	padding: 11px;
	text-transform: uppercase;
	background-color: $primary;
	border: none;
	color: white;
	width: 190px;
	&:hover{
			opacity: .7;
	}
}
//catering
.subtitle{
	font-family: $book;
	font-size: 20px;
	color: #575757;
	text-align: center;
	margin: 20px 0;
}
.catering-menu{
	border: solid 3px $primary;
	border-radius: 30px;
	width: 70%;
	margin: auto;
	padding: 20px 40px;
	.catering-add{
		width:100%;
			tr{
				td{
					padding: 10px 0;
					// padding-left: 30px;
					// text-align: right;
				}
				>:last-child{
					text-align: right !important;		
				}
			}
	}
}
.email{
	color: #147644;
	font-size: 17px;
	text-align: center;
    margin: 20px 0 50px 0;
}
.cater-menu{
	display: grid;
	grid-template-columns: 300px auto;
	// height: 300px;
	background-color: #641114;
	margin: 2.5px 0;
	
	.text{
		margin: 95px 0;
		color: white;
		font-family: $bold;
		font-size: 35px;
		// margin: auto;
		width: 100%;
		text-align: center;
	}
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		filter: brightness(60%);
	}
}
.cater-menu:hover{
	background-color: #bf2227;
	img{
		filter: none;
	}
}
.m-top{
	margin-top: 50px;
}
.terms{
	overflow-x: hidden;
	padding-bottom: 30px;
	.title{
		font-size: 17px;
		font-weight: bold;
		font-family: $bold;
		text-align: center;
		padding: 50px 0px 20px 0px;
	}
	.conditions{
		font-family: $book;
		padding: 0 200px;
	}
	.sub{
		text-align: center;
		text-transform: uppercase;
		margin: 20px 0 40px 0;
	}
}
//catering-view
.menu-content{
	// margin-top: 100px;
	background-color: #f5f5f5;
	.main-title{
		padding: 30px;

	}
	.price{
		font-size: 40px;
		font-family: $book;
		line-height: 30px;
		text-align: center;
		.sub{
			font-size: 16px;
			font-family: $book;
		}
	}
	.subtitle{
		font-family: $book;
		font-size: 20px;
		color: #575757;
		text-align: center;
		margin: 20px 0;
	}
	.catering-menu-content{
		column-count: 2;
		padding: 40px 170px;
		word-wrap: break-word;
		grid-column-gap: 62px;
		// li{
		// 	padding: 10px 0;
		// }
	}
}
.menu-view{
	height: 130px;
	margin-top: 5%;
}
.bold{
	font-weight: bold;
}
//storetype{
.storetype{
	padding: 40px 150px;
	p{
		margin: 17px 0;
		font-family: $book;
		font-size: 15px;
	}
	ul{
		display: flex;
    	padding: 24px 0;
		a{
			margin: 0 30px 0 0;
			width: 110px;
			text-align: center;
			font-family: $book;
			li{
				padding: 12px 0;
				background-color: #bf2227;
				text-transform: uppercase;
				color: white;
				&:hover{
					opacity: .6;
				}
			}
		}
	}
}
//blog
.blog{
	// margin-top: 100px;
	padding: 20px 0;
	.gallery{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 40px;
		padding: 20px 0;
		.box{
			max-width: 400px;
			.text{
				text-align: center;
				padding: 20px;
				font-family: $bold;
				font-size: 20px;
			}
			img{
				width: 100%;
				height: 250px;
				object-fit: cover;
				object-position: center;
			}
		}
		a:hover{
			.box{
				background-color: $primary;
				.text{
					color: white;
				}
				img{
					opacity: .8;
				}
			}
		}
	}
}
.blog-post{
	.button-video {
		text-align: center;
		margin: 25px 0;
		button {
			background-color: #046d38;
			color: white;
		}
	}
	.main-title{
		padding-top:20px;
	}
	.post{
		padding: 20px 100px;
		img{
			width: 100%;
			max-height: 450px;
			object-fit: cover;
			object-position: center;
		}
		.text{
			padding: 30px;
			font-family: $book;
			font-size: 15px;
		}
	}
}
@media (max-width: 700px){
	.blog-post {
		.post {
			margin-bottom: 50px;
		}
	}
	.kiosk {
		.main-title {
			margin: 0 50px;
		}
	}
	.blog-post{
		.post{
			padding: 0;
			img{
				padding-top: 20px;
			}
		}
	}
	.blog{
		.gallery{
			grid-template-columns: 1fr;
			.box{
				margin-left: auto;
				margin-right: auto;
				
			}

		}
	}
	.storetype{
		.main-title{
			text-align: center;
		}
		padding: 40px 20px;
	}
	.kiosk{
		.take-out-menu{
			.grid-menu{
				grid-template-columns: 1fr;
			}
		}
	}
	.menu-content{
		.menu{
			column-count: 1;
			padding: 0 20px;
		}
		.catering-menu-content{
			padding: 0 20px;
			
		}
	}
	.catering-menu{
		width: 90%;
		padding: 0;
		.catering-add {
			display: grid;
			grid-template-rows: 1fr;
			padding: 15px 10px; 
			tr{
				td{
					padding: 10px 30px;
					// padding-left: 30px;
					// text-align: right;
				}
				>:last-child{
					text-align: right !important;		
				}
			}
			
		}
	}
	.cater-menu{
		grid-template-columns: 1fr;
		// grid-template-rows: 150px 1fr;
		// height: 300px;
		.text {
			margin: 14px 0;
			font-size: 30px;
		}
		img {
			height: 150px;
		}
	}
	.terms{
		.conditions{
			padding: 15px;
		}
	}
}
//kiosk
.kiosk{
	margin-top: 30px;
	overflow-x: hidden;
	.links{
		position: fixed;
		left: 0;
		ul{
			margin-top: 100px;
			margin-left: -123px;
			transform: rotate(270deg);
			display: flex;
			justify-content: space-between;
			/* column-gap: 20px; */
			width: 300px;
			flex-direction: row-reverse;
			a{
				margin: 0px 0px 0px 4px;
				background-color: #641114;
					&:hover{
						background-color: #bf2227;
					}
				li{
					text-align: center;
					padding: 12px 0;
					color: white;
					text-transform: uppercase;
					font-size: 11px;
				}
				&.active{
					background-color: #bf2227;
				}
			}
		}
	
	}
	.sub{
		text-align: center;
		color: #373737;
		font-size: 20px;
		font-family: $book
	}
	.take-out-menu{
		margin: 40px 0 0 0;
		.grid-menu{
			display: grid;
			grid-template-columns: 1fr 1fr;
			.text{
				background-color: #fbfbfb;
				.title{
					padding: 30px 70px 0px 70px;
					color: #046d38;
					font-family: $book;
					font-weight:bold;
					font-size: 40px;
					text-transform: uppercase
				}
				.menu{
					width:100%;
					padding:  40px 70px 40px 70px;
					table{
						width:100%;
						tr td:nth-child(2) { /* I don't think they are 0 based */
							text-align: right;
						}
					}
				}
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
			
		}
		>:nth-child(even){
			img{
				order: 2;
			}
		}
	}
}

@media (max-width: 970px){
	.blog {
		.gallery {
			margin-bottom: 50px;
		}
	}
	.careers {
		.application {
			margin-bottom: 50px;
		}
	}
	.kiosk {
		.take-out-menu {
			.grid-menu {
				grid-template-columns: 1fr;
				img {
					min-height: 300px;
				}
			}
		}
	}
	.store{
		height: 128px;
	}
	.stores{
		height: 270px !important;
		padding: 55px 0 80px 0 !important;
		.grid{
			grid-template-columns: 1fr 1fr 1fr !important;
			img{
				height: 100px !important;
				transition: height .3s;
			}
			.text{
				font-size: 18px !important;
			}
		}
	}
	.featured{
		display: none !important;
		visibility: hidden;
	}
	.featured-product{
		height: 300px !important;

	}
	.careers{
		.now-hiring{
			padding: 30px 20px;
			.position{
				display: grid;
				grid-template-columns: 1fr;
				grid-gap: 0 33px;
				padding: 30px 0px;
			}
			
		}
		.application{
			padding: 0px 30px;
		}
	}
	.history .grid-history { 
		img{
			min-height: 300px;
		}
		.text {
			margin: 25px 20px;	
		}
	}
	.contact{
		grid-template-columns: 1fr;
		.form{
			padding: 50px 30px;
		}
		.address .m-address{
			padding: 20px 20px;

		} 
	}
	.scrolled-nav .menu{
		top: 60px !important;
	  }
	.global-nav{
		.menu{
			background-color: white;
			position: absolute;
			color: $primary;
			width: 100%;
			display: none;
			position: absolute;
			z-index: 200;
			top: 100px;
			right: 0;
			// transform: translateX(110%);
			box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.75);
			&.open{
				// transform: translateX(0%);
				display: block;
			}
			.nav-list{
				line-height: 60px !important;
				display: flex;
				flex-direction: column;
				margin:unset;
				a{
				li{
					text-align: center;
				}
				li:hover{
					background-color: $primary;
					color: white;
				}
			}
			}
		}
		.menu-button{
			display: block;
			margin-right: 33px;
		}
		
	}
	.text-container {
		font-size: 27px !important;
		img {
			max-height: 68px !important;
		}
	}
	.locator{
		grid-template-columns: 1fr;
		a{
			margin-left: auto;
			margin-right:auto;
		}
		.grid{
			justify-content: center;
			.text{
				.big-text{
					font-size: 30px;
				}
				.small-text{
					font-size: 16px;
				}
			}
		}
	}
	.global-nav {
		.nav-list {
			a{
				padding: 0;
				li{
					width:100%;
				}
			}
			a:hover{
				padding:0;
				li{
					background-color: $primary;
					color: white;
				}}}}
	
}
body.modal-open {
	padding-right: 0 !important;
  }
.banner-bg {
	// margin-top: 100px;
	height: 360px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.catch-banner{
	// margin-top: 100px;
	height: 360px;
	width: 100%;
	object-fit: cover;
    object-position: center;
}
		 
@media (min-width: 970px){
	.global-nav{
		.menu{
			display: block;
		}
		.menu-button{
			display: none;
		}
	}
	
}
@media (max-width: 555px){
	.branches {
		display: contents;
		.sidebar {
			display: unset !important;
		}
	}
	.main-slider {
		margin-bottom: 0;
	}
	.text-container img {
		max-height: 55px !important;
	}
	.global-nav .navbar-brand img {
		max-height: 58px;
	}
	.global-nav {
		.menu-button {
			margin-right: 16px;
		}
	}
	.kiosk {
		.main-title {
			font-size: 26px;
			
		}
		.take-out-menu {
			.grid-menu {
				.text {
					.menu {
						padding: 40px 20px 40px 70px;
					}
					.title {
						font-size: 26px;
					}
				}
			}
		}
	}
	.branches{
		height: 155vh;
		// display: flex;
		// flex-direction: column;
		.sidebar{
			background-color: blue;
			.list{
				height: 236px;
			}
		}
		.map{
			height: 90vh;
		}
	}
	.kiosk{
		.take-out-menu{
			.grid-menu{
				grid-template-columns: 1fr;
				img{
					min-height: 300px;
				}
			}
			>:nth-child(2){
				.text{
					order: 2;
				}
			}
		}
	}
	.history {
		>:nth-child(even){
			.text{
				order: unset;
			}
		}
		.grid-history {
			grid-template-columns:  1fr;
		}
		
	}
}
// .modal-career {
// 	display: none; /* Hidden by default */
// 	position: fixed; /* Stay in place */
// 	z-index: 1; /* Sit on top */
// 	padding-top: 400px; /* Location of the box */
// 	left: 0;
// 	top: 0;
// 	width: 100%; /* Full width */
// 	height: 100%; /* Full height */
// 	overflow: auto; /* Enable scroll if needed */
//   }
//   .modal-content {
// 	background-color: #fefefe;
// 	margin: auto;
// 	border: 1px solid #888;
// 	width: 60%;
//   }
//   .close {
// 	color: #aaaaaa;
// 	float: right;
// 	font-size: 28px;
// 	font-weight: bold;
//   }
  
//   .close:hover,
//   .close:focus {
// 	color: #000;
// 	text-decoration: none;
// 	cursor: pointer;
//   }
  .modal-dialog{
	  top: 200px;
  }
//modal

.modal-career {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
  }
  
  /* Modal Content */
  .modal-content {
	background-color: #fefefe;
	margin: auto;
	border: 1px solid #888;
	width: 90%;
  }
  
  /* The Close Button */
  .close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
  }
.modal-backdrop.in {
	opacity: 0;
}
.modal-open {overflow-y: auto;
padding-right: unset;
}
input[type="file"] {
    display: block;
    border: 1px solid #ccc;
	border-radius: 4px;
	width: 100%;
    height: 30px;
}
@media (min-width: 555px){
	.modal-content{
		padding: 20px;
	}
	
	.branches{
		height: 90vh;
		display: flex;
		flex-direction: row;
		.map{
			position: relative;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
		}
	}
}
.branches{
	opacity: .7;
	// margin-top: 100px;
	position: relative;
	padding-bottom: 20px;
	.sidebar{
		display: flex;
		flex-direction: column;
		flex-basis: 30%;
		background-color: #f5f5f5;
		.navigation{
			.search{
				height: 80px;
				width: 100%;
			}
			.menus{
				display: grid;
				justify-content: center;
				grid-template-columns: 1fr 1fr 1fr;
				width: 100%; 
				text-align: center;
				grid-gap: 23px;
				justify-items: center;
				text-transform: uppercase;
				font-size: 11px;
				font-weight: bold;
				color: $primary;
				.menu-button{
					padding: 7px 10px;
					&:hover{
						background-color: #bf2227 !important;
						color: white;
					}
				}
				button{
					border: none;
					background-color: transparent;
					img{
						max-height:60px;
						// &:hover{
						// 	opacity: .5;
						// }
					}
				}
			}
		}
		.list{
			position: relative;
			overflow-y: scroll;
			height: 100%;
			ul{
				position: relative;
				height: 236px;
				button{
					width: 100%;
					border: none;
					text-align: left;
					background-color: unset;
					li{
						padding: 10px 20px;
						font-family: $book;
						.outlet{
							font-weight: bold;
							line-height: 30px;
							font-size: 17px;
							color: $primary;
						}
						.address{ 
							font-size: 13px;
						}
					}
					&:hover{
							background-color: #737373;
							color: white;
							.outlet{
								color: white;
						}
					}
				}
			}
		}
	}
	.map{
		flex-basis: 70%;
		background-color: $primary;
	}
} 


.search-center{
	width: 100%;
    padding: 40px 40px 10px 40px;
}
.list-outlet{
	text-align: left;
	width: 100%;
    border: none;
    background-color: unset;
	li{
		padding: 10px 20px;
		font-family: $book;
		.outlet{
			font-weight: bold;
			line-height: 30px;
			font-size: 17px;
			color: $primary;
		}
		.address{
			font-size: 13px;
		}
	}
	&.active,&:hover{
		li{
			background-color: #bf2227;
			color: white;
			.outlet{
				color: white;
			}
		}
	}
}
.hide{
	display: block;
}
.autocomplete-active {
	background-color: $primary !important; 
	color: #ffffff; 
  }

  input[type=file] {
	display: block !important;
	right: 1px;
	top: 1px;
	height: 34px;
	opacity: 0;
  width: 100%;
	background: none;
	position: absolute;
  overflow: hidden;
  z-index: 2;
}

.control-fileupload {
	display: block;
	border: 1px solid #d6d7d6;
	background: #FFF;
	border-radius: 4px;
	width: 100%;
	height: 36px;
	line-height: 36px;
	padding: 0px 10px 2px 10px;
  overflow: hidden;
  position: relative;
  
  &:before, input, label {
    cursor: pointer !important;
  }
  /* File upload button */
  &:before {
    /* inherit from boostrap btn styles */
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    // vertical-align: middle;
    cursor: pointer;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    border: 1px solid #cccccc;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-bottom-color: #b3b3b3;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: color 0.2s ease;

    /* add more custom styles*/
    content: 'Browse';
    display: block;
    position: absolute;
    z-index: 1;
    top: 2px;
    right: 2px;
    line-height: 20px;
    text-align: center;
  }
  &:hover, &:focus {
    &:before {
      color: #333333;
      background-color: #e6e6e6;
      color: #333333;
      text-decoration: none;
      background-position: 0 -15px;
      transition: background-position 0.2s ease-out;
    }
  }
  
  label {
    line-height: 24px;
    color: #999999;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    z-index: 1;
    margin-right: 90px;
    margin-bottom: 0px;
    cursor: text;
  }
}
.icon-search{
	display: grid;
	grid-template-columns: auto 38px auto;
	position: relative;
	span{
	padding: 9px 13px;
    text-align: center;
    vertical-align: middle;
    padding-right: 24px;
	}
	.autocomplete-items{
		grid-column-start: 1;
		grid-column-end: 3;
		position: absolute;
		top: 36px;
		background: white;
		width: 100%;
		
		// margin-left: 51px;
		div{
		left:0;
		right:0;
		width: 100%;
		&:hover{
			background-color: $primary;
			color: white;
		}
		}
	}
}

.active-menu{
	background-color: #bf2227 !important;
	color: white;
}
.active-branch{
	background-color: #bf2227 !important;
	color: white !important;
	li{
		.outlet{
			color: white !important;
		}
	}
	
}

.txt-shadow{
	text-shadow: -2px 2px 3px rgba(0, 0, 0, 0.53);//1px -2px 5px rgba(8, 8, 8, 0.53);
}
.slick-arrow{
	background: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    color: rgba(0,0,0,.3);
    transition: color 150ms;
	font-size: 40px;
	background-color: #909090;
    border-radius: 50%;
    width: 60px;
    opacity: 0.4;
}
.slick-list {
	width: 100% !important;
}
.slick-prev{
	left: 10px;
	&:hover{
		color: white;
	}
}
.slick-next{
	right: 10px;
	&:hover{
		color: white;
	}
}

.gm-style img {
    max-width: none;
    margin: 0 auto;
    width: 100%;
    right: 0;
    margin: auto !important;
}
.loader{
	position: absolute;
    height: 100%;
    width: 100%;
	z-index: 1;
}

.spinner{
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid $primary;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin-top: 300px;
}
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }